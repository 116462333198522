import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileAudio, faPlus, faTrash, faUpload, faXmark } from '@fortawesome/free-solid-svg-icons'; 
import { fileUpload } from '../../services/apiService';
import '../../custom.css'

interface Field {
    name: string;
    label: string;
    placeholder?: string;
    disabled?: boolean;
    type?: string;
    value?: string | any;
    customField?: boolean;
    id?: any;
    limit?: any;
}

interface FormProps {
    fields: Field[];
    handleChange: (event: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>), field: any) => void;
    data: any;
    handleDeleteCustomField?: any;
    audioConvert?: any;
    selectedTextForConvertion?: string;
    onboarding_audio_url?: string;
    model_upload?:any;
    model_url?: string;
    image_upload?:any;
    image_url?: string;
    feature_audio?: string;
    story_audio?: string;
    storyCount?: string;
    featureCount?: string;
    tracking_mode_list?: string[];
    template_type_list?: string[];
    animation_type_list?: string[];
    storyline_type_list?: string[];
    feature_type_list?: string[];
    selectModelStatus?: any;
    selectModelStatusReturn?:(data: any) => void;
    audioUploaded?: any;
    imageUploaded?: any;
    modelUploaded?: any;
    alreadyAudioUploaded?: any;
    alreadyImageUploaded?: any;
    alreadyModelUploaded?: any;
}

const Form: React.FC<FormProps> = (props) => {
    const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [storyOrFeatureCount,setStoryOrFeatureCount] = useState(0);
    // const [featureCount,setFeatureCount] = useState<string>('');
    const [tracking_mode_list,setTracking_mode_list] = useState<string[]>([])
    const [template_type_list,setTemplate_type_list] = useState<string[]>([])
    const [animation_type_list,setAnimation_type_list] = useState<string[]>([])
    const [storyline_type_list,setStoryline_type_list] = useState<string[]>([])
    const [feature_type_list,setFeature_type_list] = useState<string[]>([])

    const [audioUploaded,setAudioUploaded] = useState(false);
    const [imageUploaded,setImageUploaded] = useState(false);
    const [modelUploaded,setModelUploaded] = useState(false);

    const [alreadyAudioUploaded,setAlreadyAudioUploaded] = useState(false);
    const [alreadyImageUploaded,setAlreadyImageUploaded] = useState(false);
    const [alreadyModelUploaded,setAlreadyModelUploaded] = useState(false);

    useEffect(()=>{
        setAlreadyAudioUploaded(props.alreadyAudioUploaded)
    },[props.alreadyAudioUploaded])

    useEffect(()=>{
        setAlreadyImageUploaded(props.alreadyImageUploaded)
    },[props.alreadyImageUploaded])

    useEffect(()=>{
        setAlreadyModelUploaded(props.alreadyModelUploaded)
    },[props.alreadyModelUploaded])

    useEffect(()=>{
        setAudioUploaded(props.audioUploaded)
    },[props.audioUploaded])

    useEffect(()=>{
        setImageUploaded(props.imageUploaded)
    },[props.imageUploaded])

    useEffect(()=>{
        setModelUploaded(props.modelUploaded)
    },[props.modelUploaded])

    useEffect(()=>{
        setTracking_mode_list(props.tracking_mode_list ?? [])
    }, [props.tracking_mode_list])

    useEffect(()=>{
        setTemplate_type_list(props.template_type_list ?? [])
    }, [props.template_type_list])

    useEffect(()=>{
        setAnimation_type_list(props.animation_type_list ?? [])
    }, [props.animation_type_list])

    useEffect(()=>{
        setStoryline_type_list(props.storyline_type_list ?? [])
    }, [props.storyline_type_list])

    useEffect(()=>{
        setFeature_type_list(props.feature_type_list ?? [])
    }, [props.feature_type_list])

    useEffect(() => {
        if(props.storyCount){
            setStoryOrFeatureCount(Number(props.storyCount))
        }
    }, [props.storyCount])
   
    useEffect(() => {
        if(props.featureCount){
            setStoryOrFeatureCount(Number(props.featureCount))
        }
    }, [props.featureCount])

    useEffect(() => {
        if (props.selectedTextForConvertion) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                Text_for_audio: props.selectedTextForConvertion
            }));
        }
    },[props.selectedTextForConvertion])

    useEffect(() => {
        props.data && setFormValues(
            props.fields.reduce((acc, field) => ({
                ...acc,
                [field.name]: props.data[field.name] ?? field.value ?? ""
            }), {})
        );
    }, [props.data])

    useEffect(() => {
        if (props.onboarding_audio_url) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                onboarding_audio_url: props.onboarding_audio_url
            }));
        }
    }, [props.onboarding_audio_url]);

    useEffect(() => {
        if (props.model_url) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                uploaded_model: props.model_url
            }));
        }
    }, [props.model_url]);

    useEffect(() => {
        if (props.image_url) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                branding_bg_url: props.image_url
            }));
        }
    }, [props.image_url]);

    useEffect(()=>{
        if(props.feature_audio){
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                audio_url: props.feature_audio
            }));
        }
    },[props.feature_audio])

    useEffect(()=>{
        if(props.story_audio){
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                audio_url: props.story_audio
            }));
        }
    },[props.story_audio])

    const handleChange = (event: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>), field: boolean) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        props.handleChange(event, field);
    };

    const audioConvert = (e:any) => {
        props.audioConvert(e,storyOrFeatureCount.toString());
    };

    const handleFileChange = (event:any) => { 
        setSelectedModel(event.target.files[0]); 
    }; 

    const handleImageChange = (event:any) => { 
        setSelectedImage(event.target.files[0]); 
    };

    const handleModelSubmit = async (event:any) => { 
        event.preventDefault(); 
        if (!selectedModel) {
            alert('No file selected!');
            return;
        }
        else{
            const formData = new FormData();
            formData.append('file', selectedModel);
            try { 
                const result = await props.model_upload(formData)
                if(result.status == 200){
                    alert('Model uploaded sucessfully!');
                }
            } 
            catch (error) { 
                console.error('File upload error:', error); 
                alert('File upload failed!'); 
            } 
        }
    };

    const handleImageSubmit = async (event:any) => { 
        event.preventDefault(); 
        if (!selectedImage) {
            alert('No image selected!');
            return;
        }
        else{
            const formData = new FormData();
            formData.append('file', selectedImage);
            await props.image_upload(formData)
        }
    };

    const handleDeleteCustomField = (id:any, name:any) => {
        delete formValues[name];
        props.handleDeleteCustomField(id, name)
    }

    return (
        <>
            {props.fields.map((field,index) => (
                field.type == 'lineBreak' ?
                    <div key={field.name+index} style={{flexBasis: '100%',height:'0'}}></div> 
                : field.type == 'status' ?
                    <div className={(field.name != 'image_upload_status' && field.name != 'model_upload_status' && field.name != 'audio_upload_status') ? 'mb-5 mx-5 w-1/5' : 'mb-5 w-1/5'} key={field.name}>
                        {(field.name != 'image_upload_status' && field.name != 'model_upload_status' && field.name != 'audio_upload_status') && 
                            <label htmlFor={field.name} style={{color:'#0050C8'}} className="mb-2 text-sm font-medium text-gray-900 flex justify-start items-center">
                                    {field.label}
                                    <span className='ml-2'>{field.customField && <button type="button" className="flex items-center" onClick={() => handleDeleteCustomField(field.id, field.name)} ><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>}</span>
                            </label>
                        }
                        {
                            field.name == 'image_upload_status' ?
                                ((imageUploaded === true) ?
                                    <FontAwesomeIcon icon={faCheck} className='text-xl mt-6 bg-green-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>
                                :
                                    <FontAwesomeIcon icon={faXmark} className='text-xl mt-6 bg-red-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>)

                            : field.name == 'model_upload_status' ?
                                ((modelUploaded === true) ?
                                    <FontAwesomeIcon icon={faCheck} className='text-xl mt-6 bg-green-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>
                                :
                                    <FontAwesomeIcon icon={faXmark} className='text-xl mt-6 bg-red-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>)

                            : field.name == 'audio_upload_status' ?
                                ((audioUploaded === true) ?
                                    <FontAwesomeIcon icon={faCheck} className='text-xl mt-6 bg-green-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>
                                :
                                    <FontAwesomeIcon icon={faXmark} className='text-xl mt-6 bg-red-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>)
                            : field.name == 'already_image_upload_status' ?
                                <div className='flex items-center border p-1 rounded'>
                                    <p className='me-2'>Already Uploaded</p>
                                    {
                                        ((alreadyImageUploaded === true) ?
                                            <FontAwesomeIcon icon={faCheck} className='text-xl bg-green-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>
                                        :
                                            <FontAwesomeIcon icon={faXmark} className='text-xl bg-red-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>)
                                    }
                                </div>
                            : field.name == 'already_model_upload_status' ?
                                <div className='flex items-center border p-1 rounded'>
                                    <p className='me-2'>Already Uploaded</p>
                                    {
                                        ((alreadyModelUploaded === true) ?
                                            <FontAwesomeIcon icon={faCheck} className='text-xl bg-green-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>
                                        :
                                            <FontAwesomeIcon icon={faXmark} className='text-xl bg-red-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>)
                                    }
                                </div>
                            : field.name == 'already_audio_upload_status' &&
                                <div className='flex items-center border p-1 rounded'>
                                    <p className='me-2'>Already Uploaded</p>
                                    {
                                        alreadyAudioUploaded === true ?
                                            <FontAwesomeIcon icon={faCheck} className='text-xl bg-green-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>
                                        :
                                            <FontAwesomeIcon icon={faXmark} className='text-xl bg-red-400 px-3.5 py-3' style={{borderRadius:'50%'}}/>
                                    }
                                </div>
                        }
                    </div>
                :
                    <div className={field.type === "button" ? `mb-5 ms-5 w-1/5 flex ${field.label === "convert to audio" ? 'items-start' : 'items-start'}` :`mb-5 mx-5 w-1/5`} key={field.name}>
                        {(!field.name.includes('button') && field.type !== "lineBreak") && <label htmlFor={field.name} style={{color:'#0050C8'}} className="mb-2 text-sm font-medium text-gray-900 flex justify-start items-center">
                                                        {/* {field.label?.split('_')
                                                            .map((word, i) =>
                                                                i === 0
                                                                    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                                    : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                            ).join(' ')}  */}
                                                            {field.label}
                                                            <span className='ml-2'>{field.customField && <button type="button" className="flex items-center" onClick={() => handleDeleteCustomField(field.id, field.name)} ><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>}</span>
                                                    </label>
                        }
                        {(field.type != "select" && field.type !== "button" && field.type !== "lineBreak" && field.name !== "Text_for_audio") && <input
                            type={field.type ?? "text"}
                            name={field.name}
                            id={field.name}
                            maxLength={field.limit ? field.limit : ''}
                            style={{color:`black`,borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}}
                            className={`shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`}
                            placeholder={field.placeholder?.split('_')
                                .map((word, i) =>
                                    i === 0
                                        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                        : word.toLowerCase()
                                ).join(' ')}
                            disabled={field.disabled ?? false}
                            {...(field.type !== 'file' && { 
                                value: formValues[field.name] !== undefined ? formValues[field.name] : "" 
                            })}
                            onChange={(e) => {
                                if(field.name == 'select_model'){
                                    handleFileChange(e)
                                }
                                else if(field.name == 'select_image'){
                                    handleImageChange(e)
                                }
                                else{
                                    handleChange(e, field.customField ?? false)
                                }

                                if(field.limit && e.target.value.length == Number(field.limit)){
                                    alert(`Only ${field.limit} character allowed for this field. Your last typed letter will be added.`)
                                }
                            }}
                        />}

                        {
                            field.name === "Text_for_audio" && 
                            <textarea 
                                name={field.name}
                                id={field.name}
                                {...(field.type !== 'file' && { 
                                    value: formValues[field.name] !== undefined ? formValues[field.name] : "" 
                                })}
                                placeholder={field.placeholder?.split('_')
                                    .map((word, i) =>
                                        i === 0
                                            ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            : word.toLowerCase()
                                    ).join(' ')}
                                className='shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start h-[150px]'
                                style={{color:`black`,borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF',whiteSpace:'pre-wrap',overflowWrap:"break-word",verticalAlign:'top'}}
                                onChange={(e) => {
                                    handleChange(e, field.customField ?? false)
                                }}
                            >

                            </textarea>
                        }

                        {field.type == "select" && (field.name == "account_name" || field.name == "story_name") &&
                            <select name={field.name} style={{color:'#0050C8',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                {
                                    field.value.map((story: any, i: any) => (
                                        <option value={story} key={i}>{story}</option>
                                    ))
                                }
                            </select>}

                        {field.type == "select" && field.name == "feature_name" &&
                            <select name={field.name} style={{color:'#0050C8',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                {field.value[formValues["story_name"]]?.map((feature: string, i: number) => (
                                    <option value={feature} key={i}>{feature}</option>
                                )) || <option value="">Select Story</option>}
                            </select>
                        }

                        {field.type == "select" && (field.name == "tracking_mode" || field.name == "template_type" || field.name == "animation_type" || field.name == "storyline_type" || field.name == "feature_type") &&
                            <select name={field.name} style={{color:'black',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="">Select a option</option>
                                {   field.name == "tracking_mode" ? tracking_mode_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                    :(field.name == "template_type")?
                                    template_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                    :(field.name == "animation_type")?
                                    animation_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                    :(field.name == "storyline_type")?
                                    storyline_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                    :
                                    feature_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                }
                            </select>
                        }

                        {(field.type === "button" && field.label === "Convert to audio") &&
                            <button className="p-2.5 bg-blue-200 w-[300px] rounded mt-7" style={{backgroundColor:'#0F6FFF',color:'white'}} onClick={(e)=>{audioConvert(e)}} ><FontAwesomeIcon icon={faFileAudio} className='text-sm' /> {field.label}</button>
                        }

                        {(field.type === "button" && field.label === "Upload Model") &&
                            <button className="p-2.5 bg-blue-200 w-[300px] rounded mt-7" style={{backgroundColor:'#0F6FFF',color:'white'}} onClick={(e)=>{handleModelSubmit(e)}} ><FontAwesomeIcon icon={faUpload} className='text-sm' /> {field.label}</button>
                        }

                        {(field.type === "button" && field.label === "Upload Image") &&
                            <button className="p-2.5 bg-blue-200 w-[300px] rounded mt-7" style={{backgroundColor:'#0F6FFF',color:'white'}} onClick={(e)=>{handleImageSubmit(e)}} ><FontAwesomeIcon icon={faUpload} className='text-sm' /> {field.label}</button>
                        }
                    
                    </div>
            ))}
        </>
    );
};

export default Form;