import { useEffect, useState } from "react";
import CustomFieldCreateBtn from "../CustomFieldCreateBtn";
import Form from "./Form";
import { createProject, deleteConfiguratorProjectCustomField, fetchConfiguratorProjectData, fetchOptionsAccount, audioConvert, audioUpload, fileUpload , projectTableColumnFetch , audioEnhancer} from "../../services/apiService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import { AxiosError } from 'axios';
import '../../custom.css'
  

interface ProjectFormProps {
    setError: any;
    setIsError: any;
}

const ProjectForm: React.FC<ProjectFormProps> = ({ setError, setIsError }) => {
    const [projectCount, setProjectCount] = useState(0);
    const [projectData, setProjectData] = useState<any[]>([{onboarding_audio_url:'',uploaded_model:''}]);
    const [accountOption, setAccountOption] = useState([]);
    const [audioUrl, setAudioUrl] = useState(''); 
    const [audioUploaded,setAudioUploaded] = useState(false);
    const [imageUploaded,setImageUploaded] = useState(false);
    const [modelUploaded,setModelUploaded] = useState(false);
    const [alreadyAudioUploaded,setAlreadyAudioUploaded] = useState(false);
    const [alreadyImageUploaded,setAlreadyImageUploaded] = useState(false);
    const [alreadyModelUploaded,setAlreadyModelUploaded] = useState(false);

    const navigate = useNavigate();

    const defaultFields = [
        { name: 'project_name', label: 'Story Name', placeholder: 'Story name', type: "text" },
        { name: 'code', label: 'Code', placeholder: 'Story code' },
        { name: 'division', label: 'Category', placeholder: 'Story division' },
        { name: 'product_model', label: '3D Model Title', placeholder: 'Product model' },

        { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
        { name: 'already_image_upload_status', label: 'Branding Image', placeholder: 'Already image upload status',type:'status'},
        { name: 'select_image', label: 'Upload Branding Image(500 x 120)', placeholder: 'select image' , type:'file'},
        { name: 'img_button', label: 'Upload Image', placeholder: 'Upload Image',type:'button'},
        // { name: 'branding_bg_url', label: 'Branding background url', placeholder: 'Branding background URL',disabled:true },
        { name: 'image_upload_status', label: 'Image upload status', placeholder: 'Image upload status',type:'status'},

        { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
        { name: 'already_model_upload_status', label: '3D Model', placeholder: 'Already model upload status',type:'status'},
        { name: 'select_model', label: 'Upload 3D Model (.fbx within 50 mb)', placeholder: 'select model' , type:'file'},
        { name: 'model_button', label: 'Upload Model', placeholder: 'Upload Model',type:'button'},
        // { name: 'uploaded_model', label: 'Uploaded Model URL', placeholder: 'Uploaded Model URL',disabled:true },
        { name: 'model_upload_status', label: 'Model upload status', placeholder: 'Model upload status',type:'status'},

        { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
        { name: 'already_audio_upload_status', label: 'Audio', placeholder: 'Already audio upload status',type:'status'},
        { name: 'Text_for_audio', label: 'Input Text For Voiceover', placeholder: 'Text for audio' , limit:'300'},
        { name: 'audio_button', label: 'Convert to audio', placeholder: 'convert to audio',type:'button'},
        // { name: 'onboarding_audio_url', label: 'Onboarding audio url', placeholder: 'Onboarding audio URL',disabled:true },
        { name: 'audio_upload_status', label: 'Audio upload status', placeholder: 'Audio upload status',type:'status'},

        // { name: 'android_app_url', label: 'Android app url', placeholder: 'Android app URL' },
        // { name: 'ios_app_url', label: 'IOS app url', placeholder: 'iOS app URL' },
        // { name: 'webgl_app_url', label: 'WebGL app url', placeholder: 'WebGL app URL' },

        { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'}, 
        { name: 'tracking_mode', label: 'Tracking mode', placeholder: 'Tracking mode',type:'select' },
        // { name: 'model_upload', label: 'Tracking mode', placeholder: 'Tracking mode' },
        // { name: 'template_type', label: 'Template type', placeholder: 'Template type', type:'select', customField: false }
    ];

    const [projectFields, setProjectFields] = useState<{ [key: string]: typeof defaultFields }[]>([]);
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingMes,setLoadingMes]= useState('')
    const [popupForAudioComparision,setPopupForAudioComparision] = useState(false)
    const [enhancedTextVersion,setEnhancedTextVersion] = useState('')
    const [tracking_mode_list,setTracking_mode_list] = useState([])
    const [template_type_list,setTemplate_type_list] = useState([])
    const [sms,setSms] = useState(false)

    const transformProjectData = (data: any) => {
        return {
            account_name: data.account_name,
            project_name: data.project_name,
            code: data.code,
            division: data.division,
            product_model: data.product_model,
            bg_image_url: data.bg_image_url,
            Text_for_audio: data.Text_for_audio,
            onboarding_audio_url: data.onboarding_audio_url,
            uploaded_model: data.uploaded_model,
            android_app_url: data.android_app_url,
            ios_app_url: data.ios_app_url,
            webgl_app_url: data.webgl_app_url,
            branding_bg_url: data.branding_bg_url,
            tracking_mode: data.tracking_mode,
            template_type: data.template_type,
            project_custom_fields: data.project_custom_fields,
        };
    };

    useEffect(()=>{
        if(projectData[0]){
            if(projectData[0]['branding_bg_url'] && projectData[0]['branding_bg_url'] != ''){
                setAlreadyImageUploaded(true)
            }
            if(projectData[0]['onboarding_audio_url'] && projectData[0]['onboarding_audio_url'] != ''){
                setAlreadyAudioUploaded(true)
            }
            if(projectData[0]['uploaded_model'] && projectData[0]['uploaded_model'] != ''){
                setAlreadyModelUploaded(true)
            }
        }
    },[projectData])

    useEffect(() =>{
        setLoading(true)
        async function colFetchFun1(){
            const res = await projectTableColumnFetch('tracking_mode_list')
            const values = res.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            setTracking_mode_list(values)

            const res2 = await projectTableColumnFetch('template_type_list')
            const values2 = res2.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            setTemplate_type_list(values2)
            setLoading(false)
        }
        colFetchFun1()
    },[])

    useEffect(() => {
        setProjectCount(projectData.length);
    }, [projectData]);

    useEffect(() => {
        setLoading(true)
        const getData = async () => {
            try {
                const result = await fetchConfiguratorProjectData(String(code));
                if (result.status === 200) {
                    setProjectData([transformProjectData(result.data.project)]);
                    setLoading(false);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                
            }
        };


        const getAccountsOpptions = async () => {
            try {
                const result = await fetchOptionsAccount();
                if (result.status === 200) {
                    setAccountOption(result.data.accounts);
                    setLoading(false);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                
            }
        };

        if (code != null) {
            getData();
        } else {
            setProjectData([]);
            setProjectCount(0);
        }
        getAccountsOpptions();
    }, [code, setError, setIsError]);

    useEffect(() => {
        if (projectCount === 0 && projectData.length === 0) {
            if (accountOption.length !== 0) {
                const accountField = [{ name: 'account_name', label: 'Select account', type: 'select', placeholder: 'Select account', value: accountOption }];
                defaultFields.unshift(...accountField);
            }
            setProjectFields([{ project: [...defaultFields] }]);
        } else if (projectData.length > 0) {
            if (accountOption.length !== 0) {
                const accountField = [{ name: 'account_name', label: 'Select account', type: 'select', placeholder: 'Select account', value: accountOption }];
                defaultFields.unshift(...accountField);
            }
            const initialFields = Array.from({ length: projectCount }, () => ({
                project: [...defaultFields],
            }));
            initialFields[0] && projectData.forEach((data, index) => {
                const projectFields = initialFields[index]["project"];
                const projectCustomFields = data.project_custom_fields || {};
                const initialCustomFields = Object.values(projectCustomFields).map((customField: any) => ({
                    name: customField.attribute_value,
                    label: customField.attribute_value,
                    placeholder: customField.attribute_value,
                    value: customField.value,
                    customField: true,
                    type: customField.field_type,
                    id: customField.id
                }));

                if (projectFields) {
                    projectFields.push(...initialCustomFields);
                }
            });
            setProjectFields(initialFields);
        } else if (projectFields.length < projectCount) {
            setProjectFields(prev => {
                const newFields = Array.from({ length: projectCount - prev.length }, (_, index) => ({
                    [`project`]: [...defaultFields]
                }));
                return [...prev, ...newFields];
            });
        }
    }, [projectCount, projectData, accountOption]);

    useEffect(()=>{
        if(projectData[0] && projectData[0]['project_name']){
            localStorage.setItem('project_name', projectData[0]['project_name']);
        }
    },[projectData])

    const handleProjectChange = (
        e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>),
            index: number,
            field: boolean
        ) => {
            const { name, value, type } = e.target;

            setProjectData((prev: any[]) => {
                const updatedProject = [...prev];
                const project = updatedProject[index] || {};
                project[name] = value;
                if (field) {
                    const projectCustomFields = project.project_custom_fields || {};
                    const fieldIndex = Object.keys(projectCustomFields).findIndex(key => projectCustomFields[key].attribute_value === name);
                    if (fieldIndex !== -1) {
                        projectCustomFields[fieldIndex] = {
                            ...projectCustomFields[fieldIndex],
                            value: value,
                        };
                    } else {
                        projectCustomFields[Object.keys(projectCustomFields).length] = {
                            id: projectCustomFields[Object.keys(projectCustomFields).length]?.id,
                            attribute_value: name,
                            field_type: type,
                            value: value,
                        };
                    }
                    project.project_custom_fields = projectCustomFields;
                }
                updatedProject[index] = project;
                return updatedProject;
            });
            
    };

    const audioConvert1 = async (e:any) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMes('converting')
        try {
       
            if(!projectData[0]){
                alert('Enter project name')
            }
            else if(!projectData[0]['project_name']){
                alert('Enter project name')
            }
            else if(projectData[0]['project_name'] === '' || projectData[0]['project_name'] === null){
                alert('Enter project name')
            }
            else if(!projectData[0]['Text_for_audio']){
                alert('Enter text for audio convertion')
            }
            else if(projectData[0]['Text_for_audio'] == '' || projectData[0]['Text_for_audio'] == null){
                alert('Enter text for audio convertion')
            }
            else if(projectData[0] && projectData[0]['Text_for_audio']){
                const result = await audioEnhancer(projectData[0]['Text_for_audio'])
                if(result.data.status && result.data.status == 'success'){
                    setEnhancedTextVersion(result.data.response.choices[0].message.content)
                    setPopupForAudioComparision(true)
                }
            }
            else{
                alert('something went worng in audio convertion')
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    const audioConvertionAfterComparision = async (e:any,selected:any) => {
        e.preventDefault();
        setPopupForAudioComparision(false)
        setLoading(true)
        setLoadingMes('converting')
        try {
            let text;
            if(selected === 'enhancedOne'){
                setProjectData(prevData => { 
                    const newData = [...prevData]; 
                    newData[0]['Text_for_audio'] = enhancedTextVersion; 
                    return newData; 
                })
                text = enhancedTextVersion
            }
            else{
                text = projectData[0]['Text_for_audio']
            }
            const result = await audioConvert(text);
            const url = URL.createObjectURL(result.data);
            setAudioUrl(url)
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    const audioUpload1 = async (e:any) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMes('uploading')
        try {
            const result = await audioUpload(projectData[0]['Text_for_audio'],projectData[0]['project_name'],'project',localStorage.getItem('project_name'));
            

            if(result.status == 200){
                setAudioUploaded(true)
                setProjectData((prevData) => {
                    const updatedProjectData = [...prevData];
                    if (updatedProjectData.length > 0) {
                        updatedProjectData[0].onboarding_audio_url = result.data.s3_key;
                      } else {
                        updatedProjectData.push({ onboarding_audio_url: result.data.s3_key });
                      }
                    return updatedProjectData;
                });
                setAudioUrl('')
                alert('audio uploaded successfully')
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    const model_upload = async (formData:any) => {
        if(projectData[0] && projectData[0]['project_name'] && projectData[0]['project_name'] !== ''){
            setLoading(true)
            setLoadingMes('uploading')
            const file = formData.get('file');  
            const fileName = file.name; 
            const fileExtension = fileName.split('.').pop(); 
            if(fileExtension === 'fbx'){
                const result = await fileUpload(formData,projectData[0].project_name,localStorage.getItem('project_name'))
                setProjectData((prevData) => {
                    const updatedProjectData = [...prevData];
                    if (updatedProjectData.length > 0) {
                        updatedProjectData[0].uploaded_model = result.data.s3_key;
                    } else {
                        updatedProjectData.push({ uploaded_model: result.data.s3_key });
                    }
                    return updatedProjectData;
                });
                setModelUploaded(true)
                setLoading(false);
                setSms(true)
                return result
            }
            else{
                alert('only ".fbx" files accepted')
                const result = {status : 4000}
                setLoading(false);
                return result
            }
        }
        else{
            alert('Enter project name and code')
            const result = {status : 4000}
            setLoading(false);
            return result
        }
    }

    const image_upload = async (formData:any) => {
        setLoading(true)
        setLoadingMes('uploading')
        const file = formData.get('file');
        const fileName = file.name; 
        const fileExtension = fileName.split('.').pop();
        if(fileExtension == 'png' || fileExtension == 'jpeg'){
            const img = new Image(); 
            img.onload = async () => {  
                if(img.width === 500 && img.height === 120){
                    const result = await fileUpload(formData,'project',localStorage.getItem('project_name'))
                    setImageUploaded(true)
                    setProjectData((prevData) => {
                        const updatedProjectData = [...prevData];
                        if (updatedProjectData.length > 0) {
                            updatedProjectData[0].branding_bg_url = result.data.s3_key;
                          } else {
                            updatedProjectData.push({ branding_bg_url: result.data.s3_key });
                          }
                        return updatedProjectData;
                    });
                    setLoading(false);
                    alert('image uploaded successfully')
                    return result
                } 
                else{
                    alert('only "500 x 120" resolution allowed for branding image')
                    setLoading(false);
                    const result = {status : 2000}
                    return result
                }
            };
            img.src = URL.createObjectURL(file);
        }
        else{
            alert('only ".png" and ".jpeg" are allowed for branding image')
            setLoading(false);
            const result = {status : 2000}
            return result
        }
        
    }

    const handleProjectSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setLoadingMes('saving');
        
        try {
            const result = await createProject(projectData[0], sms);
            if (result.status == 200) {
                navigate(`/configurator/form?page=stories&code=${result.data.code}`);
            } else {
                console.log(result);
            }
           
        } catch (error) {
            setIsError(true);
            if (error instanceof AxiosError && error.response) {
                if(error.response.data.error.includes('Name has already been taken')){
                    setError('Story title has already been taken');
                }
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    };
    

    const handleDeleteCustomField = (id: any, name: any) => {
        const index = projectFields[0]["project"].findIndex((item: { name: any }) => item.name === name);
        if (index !== -1 && index) {
            setProjectData((prev: any[]) => {
                const updatedProject = prev.map(project => {
                    const projectCustomFields = [...(project.project_custom_fields || [])];
                    const fieldIndex = projectCustomFields.findIndex((item: { attribute_value: any }) => item.attribute_value === name);
                    if (fieldIndex !== -1) {
                        projectCustomFields.splice(fieldIndex, 1);
                    }
                    return {
                        ...project,
                        project_custom_fields: projectCustomFields
                    };
                });
                return updatedProject;
            });
            setProjectFields((prev: any) => {
                const updatedProject = [...prev];
                const project = updatedProject[0].project;
                project.splice(index, 1)
                return updatedProject;
            });
        }
        if (id !== undefined) {
            deleteConfiguratorProjectCustomField(id);
        }
    }

    const handleFieldCreateSubmit = (input: any, index: any) => {
        setProjectData((prev: any[]) => {
            const updatedProject = [...prev];
            const project = updatedProject[index] || {};
            const projectCustomFields = project.project_custom_fields || {};
            projectCustomFields[Object.keys(projectCustomFields).length] = {
                id: projectCustomFields[Object.keys(projectCustomFields).length]?.id,
                attribute_value: input.name,
                field_type: input.type,
                value: '',
            };
            project.project_custom_fields = projectCustomFields;
            updatedProject[index] = project;
            return updatedProject;
        });
    }

    return (
        <div>
            <div style={{position:'relative'}}> 
                <form onSubmit={handleProjectSubmit}>
                    <div className="px-10 flex justify-between items-center">
                        <div className="px-10 py-5 ps-0 text-lg flex">
                                <p className='mr-4 text-xl' style={{fontSize:'30px',color:'#0050C8'}}>Story Setup </p>
                                        {/* {index !== 0 &&  */}
                                            {/* <button type="button" className="flex items-center"><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>} */}
                        </div>
                        <button
                            type="submit"
                            style={{backgroundColor:'#058C7E'}}
                            className="text-white hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-lg text-sm px-5 py-2.5 text-center w-1/5 font-bold"
                        >
                            Save & Proceed
                        </button>
                    </div>
                    <div className='h-[73vh] w-full overflow-y-auto fixed top-100 mt-5'>
                        {Array.isArray(projectFields) && projectFields.map((fieldsObj, index) => {
                            const storyKey = Object.keys(fieldsObj)[0];
                            return (
                                <div key={index}>
                                    <div className='w-full h-auto flex'>
                                        <div className='w-full px-5 flex flex-wrap'>
                                            <Form fields={fieldsObj[storyKey]} handleChange={(e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>), field) => handleProjectChange(e, index, field)} data={projectData[index] ?? []} handleDeleteCustomField={handleDeleteCustomField} audioConvert={(e:any) => audioConvert1(e)} selectedTextForConvertion={projectData[0]?.Text_for_audio} onboarding_audio_url={projectData[0]?.onboarding_audio_url} model_upload={(formData:any) => model_upload(formData)} model_url={projectData[0]?.uploaded_model} image_upload={(formData:any) => image_upload(formData)} image_url={projectData[0]?.branding_bg_url} tracking_mode_list={tracking_mode_list} template_type_list={template_type_list} audioUploaded={audioUploaded} imageUploaded={imageUploaded} modelUploaded={modelUploaded} alreadyAudioUploaded={alreadyAudioUploaded} alreadyImageUploaded={alreadyImageUploaded} alreadyModelUploaded={alreadyModelUploaded} />
                                            <div style={{flexBasis: '100%',height:'0'}}></div>
                                            <div className="mb-5 mx-5 w-1/5 items-end justify-end flex">
                                                <CustomFieldCreateBtn index={index} keyName={`project`} setFieldsCount={setProjectFields} handleFieldCreateSubmit={(inputValue: any) => handleFieldCreateSubmit(inputValue, index)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </form>
                {audioUrl && 
                    ( 
                        <div className="flex items-center justify-center mt-3" style={{position:'absolute',top:'0',left: '50%',transform: 'translateX(-50%)'}}> 
                            <audio controls className="me-2"> 
                                <source src={audioUrl} type="audio/wav" /> Your browser does not support the audio element. 
                            </audio> 
                            <button className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm text-center p-2.5 font-bold" onClick={(e)=>{audioUpload1(e)}} >upload</button>
                        </div> 
                    )
                } 

            </div>
            {loading &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',border:' 2px solid #0050C8'}} className="flex items-center rounded px-7 py-3">
                        <div className="spinner me-2"></div>
                        {
                            loadingMes === 'converting' ?  (<p className="text-blue-700">Converting please wait...</p>)  : loadingMes === 'uploading' ? <p className="text-blue-700">Uploading please wait...</p> : loadingMes === 'saving' ? <p className="text-blue-700">Saving please wait...</p> : <p className="text-blue-700">Loading please wait...</p> 
                        }
                    </div>
                </div>
            }
            {popupForAudioComparision &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',padding:'10px',width:'80%',height:'500px'}}>
                        <div className="w-full h-[20%] flex items-center justify-center">
                            <p className="text-center text-xl font-bold" style={{fontSize:'30px'}}>Please select one of the versions for audio conversion</p>
                        </div>
                        <div style={{background:'white',padding:'10px',width:'100%',display:'flex',alignItems:'start',justifyContent:'space-around',height:'80%'}}>
                            <div className="overflow-hidden w-[45%]  border h-full p-2">
                                <h4 className="text-center mb-4 underline">Previous One</h4>
                                <div className="overflow-y-auto h-[70%]">
                                    <p>{projectData[0]['Text_for_audio']}</p>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button onClick={(e)=>(audioConvertionAfterComparision(e,'previousOne'))} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Your Text</button>
                                </div>
                            </div>
                            <div className="w-[1px] h-full bg-black"></div>
                            <div className="w-[45%] overflow-hidden overflow-y-auto border h-full p-2">
                                <h4 className="text-center mb-4 underline">Enhanced One</h4>
                                <div className="overflow-y-auto h-[70%]">
                                    <p>{enhancedTextVersion}</p>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button onClick={(e)=>(audioConvertionAfterComparision(e,'enhancedOne'))} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Enhanced Text</button>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            }
        </div>
        
    );
};

export default ProjectForm;